export const getNavConfig = () => {
    const enterpriseCode =
        window.localStorage.getItem('enterprise') + '' ||
        window.localStorage.getItem('enterprise')
    const isShow = false
    return [
        {
            icon: require('/src/assets/home.svg'),
            name: '首页',
            path: '/system/index/home',
            action: 'homeOne',
        },
        {
          icon: require('/src/assets/Cooperative.png'),
          icons: require('/src/assets/cooperative_show.png'),
          name: '企业管理',
          path: '/system/enterpriseList/List',
          action: 'workEnterpriseMenu',
          children: [
              {
                  icon: require('/src/assets/user02.svg'),
                  name: '当前企业',
                  path:
                      '/system/enterprise/detail?code=' +
                      enterpriseCode +
                      '&isShow=' +
                      isShow,
                  action: 'enterpriseMenu',
              },
              {
                  icon: require('/src/assets/Cooperative01.png'),
                  name: '用工企业',
                  path: '/system/enterpriseList/List',
                  action: 'employersMenu',
              },
              {
                  icon: require('/src/assets/Cooperative02.png'),
                  name: '企业区域',
                  path: '/system/enterpriseArea/List',
                  action: 'areaMenu',
              },
              {
                  icon: require('/src/assets/Cooperative03.png'),
                  name: '企业门店',
                  path: '/system/enterpriseStores/List',
                  action: 'storesMenu',
              },
              {
                  icon: require('/src/assets/Cooperative04.png'),
                  name: '合同配置',
                  path: '/system/enterpriseContract/List',
                  action: 'contractMenu',
              },
          ],
      },
        {
            icon: require('/src/assets/user.svg'),
            icons: require('/src/assets/user_show.svg'),
            name: '人资管理',
            path: '/system/user',
            action: 'userOne',
            children: [
                {
                    icon: require('/src/assets/user01.svg'),
                    name: '当前用户',
                    path: '/system/user/detail?isShow=' + isShow,
                    action: 'customerUser',
                },

                {
                    icon: require('/src/assets/user04.svg'),
                    name: '员工列表',
                    path: '/system/user/list',
                    action: 'userMenu',
                },
                {
                  icon: require('/src/assets/user12.png'),
                  name: '待分配员工',
                  path: '/system/user/noAllocated',
                  action: 'noAllocatedListMenu',
                },
                {
                    icon: require('/src/assets/user08.png'),
                    name: '待入职员工',
                    path: '/system/user/noEmployedList',
                    action: 'noEmployedListMenu',
                },
                {
                    icon: require('/src/assets/user09.png'),
                    name: '在职员工',
                    path: '/system/user/activeEmployees',
                    action: 'activeEmployeesMenu',
                },
                {
                    icon: require('/src/assets/user10.png'),
                    name: '待离职员工',
                    path: '/system/user/pendingResignationList',
                    action: 'pendingResignationListMenu',
                },
                {
                    icon: require('/src/assets/user11.png'),
                    name: '离职员工',
                    path: '/system/user/resignationList',
                    action: 'resignationListMenu',
                },

                // {
                //   icon: require('/src/assets/user05.svg'),
                //   name: '企业客户',
                //   path: '/system/enterprise/list',
                //   action: 'enterpriseMenu'
                // },
                // {
                //   icon: require('/src/assets/user06.svg'),
                //   name: '二类户列表',
                //   path: '/system/user/erleihu',
                //   action: 'eleihuList'
                // },
                // {
                //   icon: require("/src/assets/user06.svg"),
                //   name: "企业子帐户",
                //   path: "/system/setting/subuser",
                //   action: "userDescribeSubuser"
                // },
                // {
                //   icon: require('/src/assets/user07.svg'),
                //   name: '创客信息对比',
                //   path: '/system/user/personnelComparison',
                //   action: 'comparisonOfMakerinformation'
                // },
            ],
        },
        {
            icon: require('/src/assets/user.svg'),
            icons: require('/src/assets/user_show.svg'),
            name: '合同管理',
            path: '/system/contract/list',
            action: 'contractOne',
            children: [
                {
                    icon: require('/src/assets/contract01.svg'),
                    name: '合同列表',
                    path: '/system/contract/list',
                    action: 'myContract',
                },
                {
                    icon: require('/src/assets/contract02.svg'),
                    name: '合同模板列表',
                    path: '/system/contractTemplate/list',
                    action: 'contractTemplateMenu',
                },
                {
                    icon: require('/src/assets/contract03.png'),
                    name: '合同签署人',
                    path: '/system/contractUser/List',
                    action: 'contractUserMenu',
                },
                {
                    icon: require('/src/assets/contract04.png'),
                    name: '待签署合同',
                    path: '/system/contract/noSigned',
                    action: 'noSignedeMenu',
                },
                {
                    icon: require('/src/assets/contract05.png'),
                    name: '待审核合同',
                    path: '/system/contract/noReviewed',
                    action: 'noReviewedMenu',
                },
                {
                    icon: require('/src/assets/contract06.png'),
                    name: '已审核合同',
                    path: '/system/contract/hasReviewed',
                    action: 'hasReviewedMenu',
                },
                {
                  icon: require('/src/assets/contract07.png'),
                  name: '批量合同',
                  path: '/system/contract/contractMore',
                  action: 'contractMoreMenu',
              },
            ],
        },
        {
            icon: require('/src/assets/invoice.svg'),
            name: '企业管理',
            path: '/system/project/index',
            action: 'customerMenu',
        },
        {
            icon: require('/src/assets/invoice.svg'),
            name: '项目管理',
            path: '/system/enterpriseProject/index',
            action: 'enterpriseProjectMenu',
        },
        // {
        //   icon: require("/src/assets/contract.svg"),
        //   icons: require("/src/assets/contract_show.svg"),
        //   name: "合同功能",
        //   path: "/system/contract",
        //   action: "contractOne",
        //   children: [
        //     {
        //       icon: require("/src/assets/contract01.svg"),
        //       name: "我的合同",
        //       path: "/system/contract/list",
        //       action: "myContract"
        //     },

        //   ]
        // },
        {
            icon: require('/src/assets/package.svg'),
            icons: require('/src/assets/package_show.svg'),
            name: '支付管理',
            path: '/system/task/list',
            action: 'taskOne',
            children: [
                {
                    icon: require('/src/assets/package01.svg'),
                    name: '我的工资包',
                    path: '/system/task/list',
                    action: 'taskList',
                },
                {
                    icon: require('/src/assets/package02.svg'),
                    name: '工资单模板',
                    path: '/system/task/taskTemplate',
                    action: 'taskTemplateOne',
                },
            ],
        },
     
        // {
        //   icon: require('/src/assets/invoice.svg'),
        //   icons: require('/src/assets/invoice_show.svg'),
        //   name: '发票功能',
        //   path: '/system/invoice/index',
        //   action: 'invoiceOne',
        //   children: [
        //     {
        //       icon: require('/src/assets/invoice01.svg'),
        //       name: '发票列表',
        //       path: '/system/invoice/invoice-list',
        //       action: 'invoiceMenu'
        //     },
        //     {
        //       icon: require('/src/assets/invoice02.svg'),
        //       name: '发票申请管理',
        //       path: '/system/invoice/enterprise-invoice-list',
        //       action: 'invoiceApplyMenu'
        //     },
        //     {
        //       icon: require('/src/assets/invoice03.svg'),
        //       name: '发票信息管理',
        //       path: '/system/invoice/invoiceInfo-List',
        //       action: 'invoiceInfoMenu'
        //     }
        //   ]
        // },
        {
            icon: require('/src/assets/finance.svg'),
            icons: require('/src/assets/finance_show.svg'),
            name: '财务功能',
            path: '/system/trade',
            action: 'tradeOne',
            children: [
                {
                    icon: require('/src/assets/finance01.svg'),
                    name: '交易管理',
                    path: '/system/trade/list',
                    action: 'tradeMenu',
                },
                {
                    icon: require('/src/assets/finance02.svg'),
                    name: '提现管理',
                    path: '/system/withdraw/list',
                    action: 'withdrawalMenu',
                },
                // {
                //   icon: require('/src/assets/finance03.svg'),
                //   name: '创客累计流水统计',
                //   path: '/system/trade/userStatics',
                //   action: 'taskStatisticsUserTotalMenu'
                // },
                // {
                //   icon: require('/src/assets/finance04.svg'),
                //   name: '企业支付统计',
                //   path: '/system/trade/enterpriseStatics',
                //   action: 'taskStatisticsPlatformTotalMenu'
                // },
                // {
                //   icon: require('/src/assets/finance05.svg'),
                //   name: '查看充值记录',
                //   path: '/system/trade/recharge',
                //   action: 'listConsumingRecordsMenu'
                // }
            ],
        },
        // {
        //   icon: require('/src/assets/content.svg'),
        //   name: '内容管理',
        //   path: '/system/content/list',
        //   action: 'contentEnter'
        // },
        // {
        //   icon: require('/src/assets/content.svg'),
        //   name: '新闻管理',
        //   path: '/system/newsManagement/List',
        //   action: 'newsManagement'
        // },
        // {
        //   icon:  "https://lgbfss-www.oss-cn-beijing.aliyuncs.com/www/system/menu/user.svg",
        //   name: "招聘管理",
        //   path: "/system/recruit/index",
        //   action: "recruit.enterCard"
        // },
        // {
        //   icon: require('/src/assets/agent.svg'),
        //   icons: require('/src/assets/agent_show.svg'),
        //   name: '代理管理',
        //   path: '/system/agent/agentManagement',
        //   action: 'agentManage',
        //   children: [
        // {
        //   icon: require("/src/assets/agent01.svg"),
        //   name: "代理端对账",
        //   path: "/system/agent/agentReconciliation",
        //   action: "agentReconciliation"
        // },
        // {
        //   icon: require("/src/assets/user05.svg"),
        //   name: "企业客户管理",
        //   path: "/system/agent/contractingEnterprisesList",
        //   action: "agentEnterpriseManage"
        // },
        // {
        //   icon: require('/src/assets/user03.svg'),
        //   name: '业务人员',
        //   path: '/system/agent/businessPersonnelList',
        //   action: 'businessPersonnel'
        // },
        // {
        //   icon: require("/src/assets/user03.svg"),
        //   name: "业务人员-平台",
        //   path: "/system/agent/platformBusinessPersonalList"
        // },
        // {
        //   icon: require('/src/assets/user03.svg'),
        //   name: '代理企业',
        //   path: '/system/agent/agentEnterprise',
        //   action: 'agencyEnterprise'
        // },
        // {
        //   icon: require("/src/assets/user03.svg"),
        //   name: "代理企业-平台",
        //   path: "/system/agent/platformAgentEnterprise"
        // },
        //   {
        //     icon: require('/src/assets/user03.svg'),
        //     name: '代理业绩统计',
        //     path: '/system/agent/agentStastics',
        //     action: 'agentUserStastics'
        //   },
        //   {
        //     icon: require('/src/assets/user03.svg'),
        //     name: '代理企业业绩统计',
        //     path: '/system/agent/agentEnterpriseStastics',
        //     action: 'agentEnterpriseStastics'
        //   },
        //   {
        //     icon: require('/src/assets/agent04.svg'),
        //     name: '跟进的企业',
        //     path: '/system/followEnterprise/list',
        //     action: 'followEnterpriseButton'
        //   }
        // ]
        // },
        {
            icon: require('/src/assets/agent.svg'),
            icons: require('/src/assets/agent_show.svg'),
            name: '权限管理',
            path: '/system/roleManagement',
            action: 'authorityManagement',
            children: [
                {
                    icon: require('/src/assets/content.svg'),
                    name: '角色管理',
                    path: '/system/roleManagement/List',
                    action: 'roleManagement',
                },
                // {
                //   icon: require("/src/assets/content.svg"),
                //   name: "资源管理",
                //   path: "/system/resourceManagement/List",
                //   action: "resourceManagement"
                // },
                {
                    icon: require('/src/assets/package.svg'),
                    icons: require('/src/assets/package_show.svg'),
                    name: '资源管理',
                    path: '/system/setAccess?type=resource',
                    action: 'resourceEnter',
                },
                {
                    icon: require('/src/assets/content.svg'),
                    name: '导出/导入',
                    path: '/system/roleManagement/exportResources',
                    action: 'authStatistics',
                },
            ],
        },
        // {
        //   icon: require('/src/assets/package.svg'),
        //   icons: require('/src/assets/package_show.svg'),
        //   name: '联行号查询',
        //   path: '/system/mybank/bankLine',
        //   action: 'bankLineSearch'
        // }
    ]
}
