<template>
  <div
    class="home"
    style="overflow: hidden; background: #e9ecf3"
    v-loading="loading"
    element-loading-text="您系统的时间与网络时间存在误差"
    element-loading-spinner="el-icon-loading"
  >
    <div
      style="
        position: fixed;
        height: 60px;
        left: 200px;
        right: 0;
        border-bottom: 1px solid #eee;
        background: #fff;
        z-index: 2000;
        top: 0;
        display: flex;
        background: url(https://lgbfss-www.oss-cn-beijing.aliyuncs.com/www/system/menu/header_bg.jpg)
          no-repeat;
        background-size: cover;
      "
    >
      <el-container
        style="
          align-items: center;
          justify-content: space-between;
          margin: 0 20px;
          height: 60px;
        "
      >
        <div style="color: #fff; font-weight: 600">{{ enterpriseName }}</div>

        <div style="display: flex">
          <el-button type="text" @click="back" v-if="backBtn">返回</el-button>
          <div @click="open()" style="position: relative; top: 4px">
            <el-badge :value="unread" class="item" style="width: 27px; margin: 10px 25px">
              <i class="el-icon-message-solid" style="font-size: 18px; color: #ffffff"></i>
            </el-badge>
          </div>
          <div>
            <el-popover placement="bottom" trigger="click">
              <div
                slot="reference"
                style="
                  align-items: center;
                  font-size: 14px;
                  line-height: 43px;
                  color: #909399;
                  font-weight: 600;
                "
              >
                <!-- <span
                  style="
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    background-color: #ffffff;
                    border-radius: 50%;
                    position: relative;
                    top: 8px;
                    margin-right: 10px;
                  "
                >-->
                <!-- <i
                    class="el-icon-user"
                    style="
                      font-size: 18px;
                      color: #5d5d5d;
                      top: -8px;
                      left: 3px;
                      position: relative;
                    "
                ></i>-->
                <img
                  v-if="styleLogo"
                  style="height:30px;width: 30px;  top: 8px;
                      right: 3px;
                      position: relative;
                      border-radius: 50%;"
                  :src="styleLogo"
                  alt="this is logo"
                />

                <img
                  style="height: 30px;width: 30px;  top: 8px;
                      right: 3px;
                      position: relative;
                      border-radius: 50%;"
                  v-else
                  src="../assets/logo.jpg"
                  alt
                />
                <!-- </span> -->
                <span style="color: #ffffff">{{ userInfo.name || '游客' }}</span>
                <i
                  class="el-icon-caret-bottom"
                  style="font-size: 18px; color: #ffffff; margin-left: 10px"
                ></i>
              </div>
              <div style="width: 260px">
                <!-- <p
                  @click="dialogFormVisible = true"
                  v-access
                  data-ctrl="userAddCustomerUser"
                  style="margin-bottom:20px"
                >
                  <i class="el-icon-circle-plus-outline"></i> 新建用户
                </p>-->

                <p @click="passwordVisible = true">
                  <i class="iconfont iconset"></i> 重置密码
                </p>
                <div class="vertical"></div>
                <p @click="logout()">
                  <i class="iconfont iconpassword"></i> 退出登录
                </p>
              </div>
            </el-popover>
          </div>
        </div>
      </el-container>
    </div>
    <div
      style="
        position: fixed;
        top: 0px;
        bottom: 0;
        width: 200px;
        background: #fff;
        z-index: 1999;
      "
    >
      <div style="height: 60px; text-align: center;line-height: 60px; background: #fff">
        <!-- <img
          v-if="styleLogo"
          style="height: 30px;width: 100px padding: 15px 0"
          :src="styleLogo"
          alt="this is logo"
        />

        <img style="height: 30px; padding: 15px 0" v-else src="../assets/logo.jpg" alt />-->
        <span
          style=" display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;   "
        >
          <el-popover placement="top" trigger="hover" :content="name">
            <span type="text" :hover="name" slot="reference">{{ name }}</span>
          </el-popover>
        </span>
      </div>
      <div style="height: calc(100% - 60px); overflow-y: auto; overflow-x: hidden">
        <el-menu
          unique-opened
          style="height: 100%"
          :default-active="defaultActive"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
        >
          <div v-for="(item, index1) in navConfig" :key="index1">
            <el-submenu :index="index1 + ''" v-if="item.children" v-access :data-ctrl="item.action">
              <template slot="title">
                <img
                  v-if="
                  item.children
                    .map((it) => it.path.split('?')[0])
                    .indexOf($route.path) > -1
                "
                  class="img-icon"
                  :src="item.icons"
                />
                <img v-else class="img-icon" :src="item.icon" />
                <span slot="title">{{ item.name }}</span>
              </template>
              <el-menu-item
                :index="index1 + '-' + index2"
                v-for="(it, index2) in item.children"
                :key="index2"
                @click="goPath(it.path)"
                v-access
                :data-ctrl="it.action"
              >
                <template slot="title">
                  <img
                    v-if="
                    $route.path === it.path.split('?')[0] &&
                    $route.meta.icon === it.icon
                  "
                    class="img-icon"
                    :src="$route.meta.icons"
                  />
                  <img v-else class="img-icon" :src="it.icon" />
                  <span slot="title">{{ it.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item
              v-access
              :data-ctrl="item.action"
              :index="index1 + ''"
              v-if="!item.children"
              @click="goPath(item.path)"
            >
              <img
                v-if="
                $route.path === item.path.split('?')[0] &&
                $route.meta.icon === item.icon
              "
                class="img-icon"
                :src="$route.meta.icons"
              />
              <img v-else class="img-icon" :src="item.icon" />
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
    </div>

    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        style="
          margin: 80px 20px 20px 220px;
          padding: 20px 40px 40px 40px;
          background-color: #e9ecf3;
        "
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      style="
        margin: 80px 20px 20px 220px;
        padding: 20px 40px 40px 40px;
        background-color: #e9ecf3;
      "
    ></router-view>
    <el-drawer title="消息中心" :visible.sync="drawer" :direction="'rtl'" size="600px">
      <el-tabs type="border-card" :stretch="true" @tab-click="tabChange">
        <el-tab-pane label="未读消息"></el-tab-pane>
        <el-tab-pane label="历史消息"></el-tab-pane>
      </el-tabs>
      <div
        class="message-item"
        :class="item.type"
        v-for="(item, index) in messageList"
        :key="index"
      >
        <div class="message-left">
          <i class="el-icon-message" style="font-size: 28px"></i>
          <div style="padding: 0 8px">
            <div class="message-title">
              {{ item.title }}
              <span class="unread-dot" v-if="!item.hasRead"></span>
            </div>
            <div class="message-desc">{{ item.desc }}</div>
            <div class="message-time">{{ item.pushTime }}</div>
          </div>
        </div>
        <div>
          <el-button type="text" @click="ignore(item)" v-if="!item.hasRead">忽略</el-button>
          <el-button type="text">
            <div
              v-prev
              :path="item.target"
              @click="view(item)"
            >{{ item.hasRead ? '查看' : item.buttonText }}</div>
          </el-button>
        </div>
      </div>
      <div v-if="messageList.length === 0" class="empty-box">
        <div class="empty-text">暂无未读消息！</div>
      </div>
    </el-drawer>
    <PasswordReset :formVisible.sync="passwordVisible"></PasswordReset>
    <!-- <AddRole :dialogFormVisible.sync="dialogFormVisible"></AddRole> -->
  </div>
</template>

<script>
import { api } from '/src/api/base';
import axios from 'axios';
import * as _ from 'lodash';
const request = api('/user');
const describeEnterprise = api('/enterprise')('enterprise.describe.json');
const messageList = api('/message')('message.list.json');
const messageRead = api('/message')('message.read.json');
// const Time = api('', 'GET')("time");

import { getNavConfig } from '/src/lib/navSetting';
export default {
  components: {
    PasswordReset: () => import('./passwordReset.vue'),
    // AddRole: () => import('./addRole.vue'),
  },
  data () {
    return {
      isCollapse: false,
      navShow: false,
      tags: [],
      activeIndex: '1',
      activeName: '',
      userInfo: {},
      enterprise: {},
      logoUrl: '',
      drawer: false,
      pageNumber: 1,
      pageSize: 1000,
      cloneList: [],
      messageList: [],
      unread: 0,
      passwordVisible: false,
      name: '',
      dialogFormVisible: false,
      backBtn: false,
      navConfig: [],
      defaultActive: '0',
      enterpriseName: window.localStorage.getItem('enterpriseName'),
      styleLogo: '',
      loading: false,
    };
  },
  created () {

    axios.get('/time')
      .then((response) => {

        console.log(Date.now(), response.data * 1000);
        if (Math.abs(Date.now() - response.data * 1000) < 10 * 60 * 1000) {
          this.loading = false
        }
        else {
          this.loading = true

        }
      })
      .catch(function (error) {
        console.log(error);
      })
    if (window.localStorage.getItem('syleLogo')) {

      this.styleLogo = window.localStorage.getItem('headImage') || window.localStorage.getItem('syleLogo');
      this.name = window.localStorage.getItem('enterpriseName');

    }
    this.navConfig = getNavConfig();
    this.backBtn = sessionStorage.getItem('old');
    // this.logoUrl = this.$route.params.$preload.customSetting.find(
    //   (it) => it.name === 'logo',
    // ).value;
    try {
      this.tags = JSON.parse(window.localStorage.getItem('tags')) || [];
    } catch (error) {
      this.tags = [];
    }
    this.getMessageList(false);
    // this.tags.forEach(it => (it.regexp = new RegExp(it.match)));
  },
  computed: {
    key () {
      return this.$route.fullPath;
    },
  },
  async mounted () {
    await this.getUserInfo();

    this.enterprise = await describeEnterprise({
      code: this.userInfo.enterprise,
      $ignoreRepeat: true,
    });

    this.name = this.enterprise.name
    localStorage.setItem('enterpriseEsignAccount', this.enterprise.esignAccount)
    localStorage.setItem('enterpriseCode', this.enterprise.code);
    localStorage.setItem('enterpriseName', this.enterprise.name);

  },
  methods: {
    goPath (path) {
      !path.startsWith(this.$router.currentRoute.path) &&
        this.$router.push({
          path,
        });
    },
    handleOpen () {

    },
    handleClose (key, keyPath) {
      console.log(key, keyPath);
    },
    back () {
      localStorage.clear();
      localStorage.setItem('version', '1.0');
      const old = JSON.parse(sessionStorage.getItem('old'));
      Object.keys(old).forEach((it) => {
        localStorage.setItem(it, old[it]);
      });
      sessionStorage.clear();
      location.replace('/web/system/index/home');
    },
    async ignore (item) {
      await messageRead({
        code: item.code,
      });
      this.getMessageList(false);
    },
    async view (item) {
      this.drawer = false;
      if (item.hasRead) return;
      await messageRead({
        code: item.code,
      });
      this.getMessageList(false);
    },
    async getMessageList (hasRead) {
      const res = await messageList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        hasRead: hasRead,
      });
      this.messageList = res.list;
      if (!hasRead) {
        this.unread = res.total;
      }
    },
    tabChange (val) {
      if (val.label === '历史消息') {
        this.getMessageList(true);
      }
      if (val.label === '未读消息') {
        this.getMessageList(false);
      }
    },
    goUserDetail () {
      this.$router.push({
        path: '/system/user/detail',
        query: {
          code: window.localStorage.getItem('code'),
        },
      });
    },
    goEnterpriseDetail () {
      this.$router.push({
        path: '/system/enterprise/detail',
        query: {
          code: window.localStorage.getItem('enterprise'),
        },
      });
    },
    changeActive (activeName, $event) {
      $event.stopPropagation();
      this.activeName = activeName;
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    },
    roleSet () {
      this.$router.push({
        path: '/enterprise/role-setting',
      });
    },
    close (item, $event) {
      $event.stopPropagation();
      const index = this.tags.findIndex((it) => _.isEqual(item, it));
      this.tags = this.tags.filter((it) => !_.isEqual(item, it));
      const tar = this.tags[index] || this.tags[index - 1] || {};
      this.$router.push({
        path: tar.path || '/system/index/home',
      });
    },
    logout () {
      const localStorages = window.localStorage;
      Object.keys(localStorages).forEach((it) => {
        const reg = /^custom_/;
        if (!reg.test(it)) {
          window.localStorage.removeItem(it);
        }
      });
      sessionStorage.clear();
      localStorage.setItem('version', '1.0');
      this.$router.push({
        path: '/login',
      });
    },
    async getUserInfo () {
      const result = await request('user.describe.json')({
        code: window.localStorage.getItem('code'),
        $ignoreRepeat: true,
      });
      this.userInfo = result;

    },
    open () {
      this.drawer = true;
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   let hasInsert = false;
  //   this.list.forEach(it => {
  //     const regexp = new RegExp(it.match);
  //     console.log(regexp);
  //     it.active = (regexp.test && regexp.test(to.fullPath)) || false;
  //     hasInsert = hasInsert || it.active;
  //   });
  //   next();
  // }
};
</script>
<style scoped lang="scss">
.box {
  width: 400px;

  .top {
    text-align: center;
  }

  .left {
    float: left;
    width: 60px;
  }

  .right {
    float: right;
    width: 60px;
  }

  .bottom {
    clear: both;
    text-align: center;
  }

  .item {
    margin: 4px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
    padding: 8px 10px;
  }
}

.el-header,
.el-footer {
  color: #333;
}

.el-aside {
  /* background-color: #d3dce6; */
  color: #333;
  overflow-x: hidden;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
}

.el-submenu__title {
  text-align: left;
}
</style>

<style scoped>
.img-icon {
  width: 18px;
  margin-right: 6px;
}

.unactive {
  color: #666;
}

.active {
  color: #409eff;
}

.nav-ul {
  height: 64px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  justify-content: space-between;
  background: #fbfbfb;
}

.nav-app {
  border-right: 1px solid #f1f1f1;
  padding: 20px;
  color: #666;
}

/* .nav-app:hover {
  color: #409eff;
} */
.nav-tabs {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: 63px;
}

.nav-active {
  color: #409eff !important;
}

.nav-tabs > div {
  margin: 2px;
  color: #666;
  font-size: 15px;
  padding: 3px 20px;
  cursor: pointer;
  position: relative;
  padding-left: 1px;
}

.nav-tabs > div:hover {
  color: #409eff;
}

.el-icon-close {
  position: absolute;
  padding: 3px;
  display: none;
  color: red;
}

.nav-tabs > div:hover > .el-icon-close {
  display: inline-block;
}

.nav-more {
  padding: 0 20px;
}

.frame {
  display: flex;
  align-items: center;
  height: 60px;
  margin: 0 20px;
  border-bottom: 1px solid rgb(220, 223, 230);
  position: relative;
  z-index: 100;
}

.container {
  padding: 0 20px;
  height: calc(100vh - 61px);
  overflow: auto;
}

.vertical {
  height: 1px;
  width: 100%;
  background: #dcdfe6;
  margin: 12px 0;
}

.nav {
  line-height: 30px;
  list-style: none;
  cursor: pointer;
  color: #909399;
}

.nav:hover {
  background-color: #f5f7fa;
  color: #303133;
}

.focus .sub-meun-content {
  display: block;
  animation: show 0.5s;
}

.sub-meun-title {
  line-height: 60px;
}

.sub-meun-content > h4 {
  padding: 10px;
}

.sub-meun-content {
  position: absolute;
  top: 60px;
  z-index: 1000;
  border: 1px solid rgb(220, 223, 230);
  height: 0;
  overflow: hidden;
  height: 0;
  background: #f7f7f7;
  left: 0;
  right: 0;
  display: none;
  height: 260px;
  border-radius: 4px;
  padding: 10px;
}

.sub-meun-item-group {
  margin: 10px;
  margin-top: 0;
}

.sub-meun-item {
  list-style: none;
  line-height: 32px;
  font-size: 16px;
  color: #888;
  cursor: pointer;
}

.sub-meun-item:hover {
  color: #409eff;
}

@keyframes show {
  from {
    height: 100px;
  }

  to {
    height: 260px;
  }
}

.home-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 530px;
  margin: 0 auto;
  align-content: flex-start;
}

.item {
  margin: 15px 25px;
  width: 56px;
  text-align: center;
}

.title {
  color: #3a3a3a;
  margin-top: 6px;
  word-break: keep-all;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.icon-img {
  box-shadow: 0 0 15px #cecece;
  border-radius: 18px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.el-alert {
  margin-bottom: 10px;
}

.el-badge__content.is-fixed.is-dot {
  right: 10px !important;
}

.el-badge__content.is-fixed {
  top: 3px !important;
}

.message-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 10px;
}

.message-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-title {
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  flex-grow: 1;
}

.message-desc {
  font-size: 12px;
  margin: 5px 0 0;
}

.message-time {
  font-size: 12px;
  margin: 5px 0 0;
}

.hui {
  background-color: #f4f4f5;
  color: #909399;
}

.success {
  background-color: #f0f9eb;
  color: #67c23a;
}

.info {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.danger {
  background-color: #fef0f0;
  color: #f56c6c;
}

.unread-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #f56c6c;
  position: relative;
  top: -7px;
}

.empty-box {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

.empty-text {
  color: #72767b;
  position: relative;
  text-align: center;
  top: 30px;
}
</style>
